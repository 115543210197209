<template>
  <div class="auth-frame enter-phone-number">
    <div class="content white-color-bg p-y-48 p-x-42 radius-14">
      <template>
        <h3 class="bold-font fs-22 black-color-text">
          {{ $t('forms.changePassword') }}
        </h3>
        <form @submit.prevent="resetPassword()">
          <div class="show-hide-password-container">
            <BaseTextInput
              :value="form.password"
              :placeholder="$t('forms.enterPassword')"
              :type="passFieldType"
              input-id="password"
              class="m-b-18"
              :has-error="errors.has('password')"
              :message="errors.get('password')"
              @reset="errors.clear()"
              @submit="validatePassword"
              @update-value="getLoginData"
            />
            <button
              class="show-password"
              type="button"
              @click.prevent="onSwitchPass"
            >
              <OpenEye v-if="passFieldType == 'text'" />
              <ClosedEye v-else />
            </button>
          </div>

          <div class="show-hide-password-container">
            <BaseTextInput
              :value="form.confirmPassword"
              :placeholder="$t('forms.enterPassword')"
              :type="passFieldType"
              input-id="confirmPassword"
              class="m-b-18"
              :has-error="errors.has('confirmPassword')"
              :message="errors.get('confirmPassword')"
              @reset="errors.clear()"
              @submit="validatePassword"
              @update-value="getLoginData"
            />
            <button
              class="show-password"
              type="button"
              @click.prevent="onSwitchPass"
            >
              <OpenEye v-if="passFieldType == 'text'" />
              <ClosedEye v-else />
            </button>
          </div>

          <h6
            v-if="error"
            class="mb-2 erorr"
          >
            {{ $t('forms.errorMessage') }}
          </h6>

          <base-button
            :text="$t('forms.newAccount')"
            bg-color-class="main-color-bg"
            is-medium
          />
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import { OTP } from './otpMixin'
export default {
  name: 'RegisterPassword',
  mixins: [OTP],

  methods: {
    resetPassword () {
      this.validatePassword()
      if (!this.errors.hasAny()) {
        if (this.form.password === this.form.confirmPassword) {
          this.$store
            .dispatch('Globals/resetPassword', {
              reset_token: this.$store.state.Globals.reset_token,
              new_password: this.form.password,
              new_password_confirmation: this.form.confirmPassword,
              app_version: ''
            })
            .then((res) => {})
        } else {
          this.error = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../components/auth/register/authStyle';
.erorr {
  font-size: 12px;
  color: red !important;
}
</style>
