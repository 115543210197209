<template>
  <div class="auth-frame enter-phone-number">
    <div class="content white-color-bg p-y-48 p-x-42 radius-14">
      <template>
        <h3 class="bold-font fs-22 black-color-text mb-3">
          {{ $t('forms.enterPhone') }}
        </h3>
        <span> {{ $t('forms.subTitle') }}</span>
        <h5 class="d-flex align-items-center mt-2">
          <span class="mt-1"> {{ userPhone }}</span>
          <router-link
            class="edit"
            :to="{ name: 'enter-phone-number' }"
          >
            {{ $t('forms.edit') }}
          </router-link>
        </h5>
        <form
          class="mt-3"
          @submit.prevent="submitForm"
        >
          <fieldset
            class="field my-4"
            style="direction: ltr"
          >
            <input
              id="code"
              ref="input1"
              v-model="confirmForm.verificationCodeNumber1"
              name="code"
              class="mx-2"
              autofocus
              required
              @input="goNext2()"
            >
            <input
              id="code"
              ref="input2"
              v-model="confirmForm.verificationCodeNumber2"
              type="text"
              name="code"
              class="mx-2"
              @input="goNext3()"
            >
            <input
              id="code"
              ref="input3"
              v-model="confirmForm.verificationCodeNumber3"
              type="text"
              name="code"
              class="mx-2"
              @input="goNext4()"
            >
            <input
              id="code"
              ref="input4"
              v-model="confirmForm.verificationCodeNumber4"
              type="text"
              name="code"
              class="mx-2"
              @input="goNext5()"
            >
            <input
              id="code"
              ref="input5"
              v-model="confirmForm.verificationCodeNumber5"
              type="text"
              name="code"
              class="mx-2"
              @input="goNext6()"
            >
            <input
              id="code"
              ref="input6"
              v-model="confirmForm.verificationCodeNumber6"
              type="text"
              name="code"
            >
          </fieldset>

          <base-button
            :text="$t('forms.confirmCode')"
            bg-color-class="main-color-bg"
            is-medium
          />
        </form>
        <h6
          v-if="countDown != 0"
          class="text-center mt-5"
        >
          <span>0:{{ countDown }}</span>
          {{ $t('forms.reSend') }}
        </h6>

        <h6
          v-else
          class="cursor-pointer text-center mt-5"
          @click="reSendCode()"
        >
          <span>0:{{ countDown }}</span>
          {{ $t('forms.reSend') }}
        </h6>
      </template>
    </div>
  </div>
</template>

<script>
import { OTP } from './otpMixin'
export default {
  name: 'ForgetPasswordCodeVerify',
  mixins: [OTP],

  methods: {
    submitForm () {
      const code = []
      code.push(
        this.confirmForm.verificationCodeNumber1,
        this.confirmForm.verificationCodeNumber2,
        this.confirmForm.verificationCodeNumber3,
        this.confirmForm.verificationCodeNumber4,
        this.confirmForm.verificationCodeNumber5,
        this.confirmForm.verificationCodeNumber6
      )
      this.$store
        .dispatch('Globals/forgetPasswordCodeVerify', {
          phone: this.userPhone,
          code: code.join(''),
          app_version: '',
          store: ''
        })
        .then(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../components/auth/register/authStyle';
.erorr {
  font-size: 12px;
  color: red !important;
}
</style>
